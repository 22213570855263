import React, { Component } from 'react';

// styles and components
import styles from '../../styles/submissionCard.module.css';
import controller from '../../data/dataController.js'

export default class SubmissionCard extends Component {

  state = {
    loading: false,
    cityInfo: null
  }

  componentDidMount() {
    this.makeRemoteRequest();
  }

  makeRemoteRequest = async lastKey => {
    // If we are currently getting posts, then bail out..
    if (this.state.loading) { return; }

    this.setState({ loading: true });

    try { var cityInfo = await controller.city_info(this.props.cityName); }
    catch (e) { console.log(e.message); return; }

    this.setState({ 
      loading: false,
      cityInfo: cityInfo
    });

  };

  _onRefresh = () => this.makeRemoteRequest();

  render() {

    if (!this.state.cityInfo) {
      return <></>
    }

    if (!this.props.cardInfo) {
      return (
        <div className={styles.container}>
          <img src={this.state.cityInfo['HeroImage'][0]['url']} alt={this.props.cityName} className={styles.cardImage}/>
          <div className={styles.cardTextBox}>
            <p className={styles.cityNameText}>{this.props.cityName}</p>
          </div>
        </div>
      );
    }

    var cardInfo = this.props.cardInfo;

    // doing stupid date transforms 
    var startDate = new Date(cardInfo.StartDate);
    var endDate = new Date(cardInfo.EndDate);
    var diffDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

    var startDateFormat = days[startDate.getDay()] + ", " + months[startDate.getMonth()] + " " + startDate.getDate();
    var endDateFormat = days[endDate.getDay()] + ", " + months[endDate.getMonth()] + " " + endDate.getDate();

    return (
      <div className={styles.container}>
        <img src={this.state.cityInfo['HeroImage'][0]['url']} alt={this.props.cityName} className={styles.cardImage}/>
        <div className={styles.cardTextBox}>
          <div className={styles.horizontalBox}>
            <p className={styles.dateText}>{startDateFormat + " - "}</p>
            <p className={styles.dateText}>{endDateFormat}</p>
            <p className={styles.dateText}>{" (" + diffDays + " nights)"  }</p>
          </div>
          <p className={styles.titleText}>{cardInfo.Name}</p>
          <p className={styles.cityNameText}>{this.props.cityName}</p>
          <hr className={styles.hairline}></hr>
          <p className={styles.cardText}><b>Department: </b>{cardInfo.Department}</p>
          <p className={styles.cardText}><b>Attendees: </b>{cardInfo.Guests.toString()}</p>
          <p className={styles.cardText}><b>Purpose of Offsite: </b>{cardInfo.Objective}</p>
          <p className={styles.cardText}><b>Budget: $</b>{cardInfo.Budget.toString()}</p> 
        </div>
      </div>
    );
    
  }

}