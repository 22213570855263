import React from 'react';
import { useHistory } from 'react-router-dom';

// images
import oneBrexCards from '../../images/one-brex.png';
import twoBrexCards from '../../images/two-brex.png';
import threeBrexCards from '../../images/three-brex.png';
import fourBrexCards from '../../images/four-brex.png';

// styles
import styles from '../../styles/requestcard.module.css'
import gs from '../../styles/global.module.css'


const RequestCard = (props) => {

  var history = useHistory();

  var cityName = props.cityName;
  var description = props.description;
  var brexCards = props.brexCards;

  var brexCardUrl;
  switch(brexCards) {
    case 2: brexCardUrl = twoBrexCards; break;
    case 3: brexCardUrl = threeBrexCards; break;
    case 4: brexCardUrl = fourBrexCards; break;
    default: brexCardUrl = oneBrexCards;
  }

  return (
    <div className={styles.card}>
      <div className={gs.horizontalFlexBox}> 
        <p className={styles.cityName}>{cityName}</p>
        <img className={styles.brexCards} src={brexCardUrl} alt={'brex cards'}></img>
      </div>
      <p className={styles.bulletedList}>{description}</p>
      <button style={{width: 'auto'}} 
              onClick={() => history.push("/submission-page1/" + cityName)}>
              {"Request and Start Customizing"}
      </button>
    </div>  
  );

}

export default RequestCard