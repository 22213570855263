import React from 'react';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';

/* okta auth info */
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import { oktaAuthConfig, oktaSignInConfig } from './components/Auth/config';

/* components */
import Home from './components/Auth/Home';
import HeaderBar from './components/Shared/HeaderBar';
import FooterBar from './components/Shared/FooterBar';
import DestinationIndex from './components/DestinationIndex';
import DestinationPage from './components/City/DestinationPage';
import ListingPage from './components/City/ListingPage';
import SubmissionInfoPage from './components/Submission/SubmissionInfoPage';
import SubmissionPreferencePage from './components/Submission/SubmissionPreferencePage';
import SubmissionDetailsPage from './components/Submission/SubmissionDetailsPage';
import AgendaEntryPage from './components/Agendas/AgendaEntryPage';
import AgendaViewingPage from './components/Agendas/AgendaViewingPage';
import GuestViewPage from './components/Guests/GuestViewPage';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppWithRouterAccess = () => {
  const history = useHistory();

  const customAuthHandler = () => { history.push('/login'); };
  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    !originalUri ?
      history.replace(toRelativeUrl("/destinations", window.location.origin)) :
      history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const destinationIndexRoute = 
    <SecureRoute 
      path='/destinations' 
      render={() => 
        <>
          <HeaderBar isGuest={false}/>
          <DestinationIndex />
          <FooterBar/>
        </>
      } 
    />;

  const cityPageRoute = 
    <SecureRoute
      exact path='/city/:cityName'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <DestinationPage {...props} />
          <FooterBar/>
        </>}
    />;

  const activityPageRoute = 
    <SecureRoute
      exact path='/activity/:listingID'
      render={(props) =>
        <>
          <HeaderBar/>
          <ListingPage {...props } listingType={'activity'}/>
          <FooterBar/>
        </>}
    />;

  const lodgingPageRoute = 
    <SecureRoute
      exact path='/lodging/:listingID'
      render={(props) =>
        <>
          <HeaderBar/>
          <ListingPage {...props } listingType={'lodging'}/>
          <FooterBar/>
        </>}
    />;

  const restaurantPageRoute = 
    <SecureRoute
      exact path='/restaurant/:listingID'
      render={(props) =>
        <>
          <HeaderBar/>
          <ListingPage {...props } listingType={'restaurant'}/>
          <FooterBar/>
        </>}
    />;

  const submissionInfoPageRoute = 
    <SecureRoute
      exact path='/submission-page1/:cityName'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <SubmissionInfoPage {...props} />
          <FooterBar/>
        </>}
    />;

  const submissionPrefPageRoute = 
    <SecureRoute
      exact path='/submission-page2/:cityName'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <SubmissionPreferencePage {...props} />
          <FooterBar/>
        </>}
    />;

  const submissionDetailsPageRoute = 
    <SecureRoute
      exact path='/submission-page3/:cityName'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <SubmissionDetailsPage {...props} />
          <FooterBar/>
        </>}
    />;

  const agendaEntryPageRoute = 
    <SecureRoute
      exact path='/agendas'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <AgendaEntryPage {...props} />
          {/*<FooterBar/>*/}
        </>}
    />;

  const agendaViewingPageRoute = 
    <SecureRoute
      exact path='/agendas/:agendaID'
      render={(props) =>
        <>
          <HeaderBar isGuest={false}/>
          <AgendaViewingPage {...props} />
          <FooterBar/>
        </>}
    />;

  const guestViewPageRoute = 
    <SecureRoute
      exact path='/guest/:agendaID'
      render={(props) =>
        <>
          <HeaderBar isGuest={true} />
          <GuestViewPage {...props} />
        </>}
    />;


  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        {destinationIndexRoute}
        {cityPageRoute}
        {activityPageRoute}
        {lodgingPageRoute}
        {restaurantPageRoute}
        {submissionInfoPageRoute}
        {submissionPrefPageRoute}
        {submissionDetailsPageRoute}
        {agendaEntryPageRoute}
        {agendaViewingPageRoute}
        {guestViewPageRoute}
        <Route path='/login/callback' component={LoginCallback} />
        <Route path='/login' render={() => <Home config={oktaSignInConfig} />} />
        <Route render={() => <Redirect to="/destinations" />} />
      </Switch>

    </Security>
  );
};

export default AppWithRouterAccess;