import React, { useState, useEffect } from 'react';
import { layoutGenerator } from 'react-break';
import { useLocation } from 'react-router-dom';

// styles & components
import styles from '../../styles/detailsPage.module.css'
import gs from '../../styles/global.module.css'

// images
import oneBrexCards from '../../images/one-brex.png';
import twoBrexCards from '../../images/two-brex.png';
import threeBrexCards from '../../images/three-brex.png';
import fourBrexCards from '../../images/four-brex.png';

import checkmark from '../../images/amenity_check.png';

// setting up the mobile breakpoints
const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

var controller = require('../../data/dataController');

const brexCardUrl = (brexCards) => {
  var numCards;
  switch(brexCards) {
    case 2: numCards = twoBrexCards; break;
    case 3: numCards = threeBrexCards; break;
    case 4: numCards = fourBrexCards; break;
    default: numCards = oneBrexCards;
  }
  return numCards
}

const ListingPage = ( props ) => {

  const [listingInfo, setListingInfo] = useState(null);

  // getting query parameter
  const { search } = useLocation()
  var isGuest = (search === '?guest') ? true : false;

  // only called once on "component did mount"
  useEffect(() => {controller.listing_by_id([props.listingType, props.match.params.listingID])
    .then(res => {console.log(res); setListingInfo(res)})
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  var backButton = props.history.action !== 'POP' ? 
    <p className={styles.cityName} onClick={() => {props.history.goBack()}}>{"Back"}</p> :
    <></>

  var headerText = listingInfo ? (
    <>
      {backButton}
      <p className={styles.listingName}>{listingInfo['ListingName']}</p>
    </>
  ) : <></>

  // amenities grid for the lodging module
  var amenitiesGrid = <></>;
  if (listingInfo && listingInfo['Amenities'] && listingInfo['Amenities'].length > 0) {

    var amenitiesList = listingInfo['Amenities'].map(amenity => 
      <div className={styles.amenityView}>
        <img style={{width:'20px', height:'20px', padding:'0 10px 0 10px'}} src={checkmark} alt={"checkmark"}/>
        <p className={styles.amenityText}> {amenity} </p>
      </div>
    )

    amenitiesGrid = <ul className={styles.amenitiesGrid}>{amenitiesList}</ul>
  }

  // amenities grid for the lodging module
  var amenitiesGridMobile = <></>;
  if (listingInfo && listingInfo['Amenities'] && listingInfo['Amenities'].length > 0) {

    var amenitiesListMobile = listingInfo['Amenities'].map(amenity => 
      <div className={styles.amenityView}>
        <img style={{width:'20px', height:'20px', padding:'0 10px 0 10px'}} src={checkmark} alt={"checkmark"}/>
        <p className={styles.amenityText}> {amenity} </p>
      </div>
    )

    amenitiesGridMobile = <ul className={styles.amenitiesGridMobile}>{amenitiesListMobile}</ul>
  }

  // initializing the body 
  var body = listingInfo ? (
    <div className={styles.leftPage}>
      <img className={styles.heroImage} src={listingInfo['HeroImage'][0]['url']} alt={""}/>
      <p className={styles.requestTitle}>{"Description"}</p>
      <p className={styles.listingDescription}>{listingInfo['ListingDescription']}</p>
      {amenitiesGrid}
    </div>
  ) : <></>
    
  // initializing request module 
  var requestCard = listingInfo ? 
    <div className={styles.rightPage}>
      <div className={styles.card}>
      <div className={gs.horizontalFlexBox} style={{paddingBottom: '20px'}}> 
        <p className={styles.cityName2}>{listingInfo['ListingProvider']}</p>
        {isGuest ? <></> : <img className={styles.brexCards} src={brexCardUrl(listingInfo['BrexCards'])} alt={'brex cards'}></img>}
      </div>
      {listingInfo['PhoneNumber'] ?
        <>
          <p className={styles.requestTitle}>{"Phone Number"}</p>
          <p className={styles.requestBody}>{listingInfo['PhoneNumber']}</p>
        </> : <></>
      }
      <p className={styles.requestTitle}>{"Address"}</p>
      <p className={styles.requestBody}>{listingInfo['Address']}</p>
      <hr className={styles.hairline}></hr>
      <button style={{width: 'auto'}}
              onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(listingInfo['Address']))}>
              {"Get Directions"}
      </button>
    </div>
    </div> : <></>

  var mobileView = listingInfo ? 
    <div className={styles.mobileView}>
      {headerText}
      <img className={styles.heroImageMobile} src={listingInfo['HeroImage'][0]['url']} alt={""}/>
      {listingInfo['PhoneNumber'] ?
        <>
          <p className={styles.requestTitle}>{"Phone Number"}</p>
          <p className={styles.requestBody}>{listingInfo['PhoneNumber']}</p>
        </> : <></>
      }
      <p className={styles.requestTitle}>{"Address"}</p>
      <p className={styles.requestBody}>{listingInfo['Address']}</p>
      <button style={{width: '100%'}}
        onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(listingInfo['Address']))}>
        {"Get Directions"}
      </button>
      <hr className={styles.hairline}></hr>
      <p className={styles.requestTitle}>{"Description"}</p>
      <p className={styles.listingDescriptionMobile}>{listingInfo['ListingDescription']}</p>
      {amenitiesGridMobile}
    </div> : <></>

  return (
    <>
      <OnAtLeastTablet>
        <div className={styles.fullPage}>
          {headerText}
          <div className={gs.horizontalFlexBox}>
            {body}
            {requestCard}
          </div>
        </div>
      </OnAtLeastTablet>
      <OnAtMostPhablet>
        {mobileView}
      </OnAtMostPhablet>
    </>
  );

}

export default ListingPage