import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

// import styles and components
import styles from '../../styles/agendaEntry.module.css'
import controller from '../../data/dataController';


const AgendaEntryPage = (props) => {

  const history = useHistory();

  const [agendaCode, setAgendaCode] = useState("");
  const [codeCorrect, setCodeCorrect] = useState(true);
  const [overviewDict, setOverviewDict] = useState({})

  const processSubmissionList = (res) => {

    var completed = 0;
    var planned = 0;
    var employees = 0;

    res.forEach(function(submission) {
      employees += parseInt(submission['Guests']);
      new Date(submission['StartDate']) < new Date() ? completed += 1 : planned += 1;
    });

    setOverviewDict({"completed":completed, "planned": planned, "employees": employees})

  }

  useEffect(() => {
    controller.submissions()
    .then(res => processSubmissionList(res));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const buttonClick = () => {

    controller.agenda_by_id(agendaCode.toUpperCase()).then( result =>
    {
      if (result) {
        history.push('/agendas/' + agendaCode.toUpperCase())
      } else {
        setCodeCorrect(false);
      }
    });
  }

  const inputFieldStyle = codeCorrect ? styles.inputFieldCorrect : styles.inputFieldIncorrect

  const incorrectCode = codeCorrect ? 
      <></> : 
      <p className={styles.incorrectCode}>{"Code does not exist."}</p>

  return (
    <div className={styles.horizontalBox}>

      <div className={styles.overviewBox}>
        <div className={styles.horizontalFlexBox}>
          <div className={styles.verticalFlexBox}>
            <p className={styles.overviewHeaderText}>{"OFFSITES COMPLETED"}</p>
            <p className={styles.overviewNumbers}>{overviewDict['completed']}</p>
          </div>
          <div className={styles.verticalFlexBox}>
            <p className={styles.overviewHeaderText}>{"OFFSITES IN PLANNING"}</p>
            <p className={styles.overviewNumbers}>{overviewDict['planned']}</p>
          </div>
          <div className={styles.verticalFlexBox}>
            <p className={styles.overviewHeaderText}>{"EMPLOYEES ATTENDED"}</p>
            <p className={styles.overviewNumbers}>{overviewDict['employees']}</p>
          </div>
          <div className={styles.verticalFlexBox}>
            <p className={styles.overviewHeaderText}>{"TOP REASON FOR OFFSITES"}</p>
            <p className={styles.overviewDetailText}>{"Team Bonding"}</p>
          </div>
        </div>
      </div>

      <p className={styles.titleText}>{"View Agenda Details"}</p>
      <p className={styles.subtitleText}>{"Enter the agenda code provided by your concierge."}</p>
      <input className={inputFieldStyle} onChange={e => setAgendaCode(e.target.value)}/>
      {incorrectCode}
      <div className={styles.button} value={agendaCode} onClick={buttonClick}>
        <p className={styles.buttonText}>{"View Agenda"}</p>
      </div>
    </div>
  );

};

export default AgendaEntryPage;