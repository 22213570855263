import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

// styles and components 
import ListingModule from '../City/ListingModule';
import AgendaItineraryModule from './AgendaItineraryModule';

import styles from '../../styles/agendaViewingPage.module.css';
var controller = require('../../data/dataController');

const AgendaViewingPage = (props) => {

  const [agendaInfo, setAgendaInfo] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMessageModal2, setShowMessageModal2] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    controller.agenda_by_id(props.match.params.agendaID)
    .then(res => setAgendaInfo(res));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMessage = ( msg1 ) => {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ msg: msg1, code: props.match.params.agendaID})
    };
    fetch('https://offsiter-brex.herokuapp.com/concierge_message', requestOptions)
        .then(response => response.json())
        .then(data => console.log(data));

    setShowMessageModal(false)
  };

  const messageClick = () => {setShowMessageModal(true)};
  const acceptClick = () => {setShowMessageModal2(true); handleMessage('agenda accepted')};

  var conciergeBox = (
    <div className={styles.conciergeBox}>
      <div className={styles.horizontalFlexBox} style={{maxWidth: '1186px', margin:'0 auto'}}>
        <div className={styles.textBox}>
          <p className={styles.conciergeBoxTitle}>{"Please review the proposed agenda"}</p>
          <p className={styles.conciergeBoxSubtitle}>{"Click Accept Agenda if you are satisfied with your concierge’s proposed agenda. If you have questions or something doesn’t look right, message your concierge and they will get back to you as soon as possible."}</p>
        </div>
        <div>
          <button onClick={messageClick}>{"Message Concierge"}</button>
          <button className={styles.conciergeAccept} onClick={acceptClick}>{"Accept Agenda"}</button>
        </div>
      </div>
    </div>
  )

  const handleClose = () => {setShowMessageModal(false)};
  const handleClose2 = () => {setShowMessageModal2(false)};

  const modalStyles = {
    content: {
      position: 'absolute',
      top: '20%',
      left: '30%',
      width: '40%',
      right: 'auto',
      bottom: 'auto',
    },
  } 

  var messageModal = (
    <ReactModal isOpen={showMessageModal} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.modalVerticalFlexBox}>
        <p className={styles.modalTitle}>{"Send your concierge a message"}</p>
        <p className={styles.modalSubtitle}>{"The concierge will review your message and get back to you through email."}</p>
        <input className={styles.messageInput} onChange={e => setMessage(e.target.value)}/>
        <button className={styles.modalButtonYes} onClick={() => handleMessage(message)}>{"Send Message"}</button>
        <button className={styles.modalButtonNo} onClick={handleClose}>{"Cancel"}</button>
      </div>
    </ReactModal>
  )

  var messageModal2 = (
    <ReactModal isOpen={showMessageModal2} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.modalVerticalFlexBox}>
        <p className={styles.modalTitle}>{"Woohoo!! You're going on an offsite soon!"}</p>
        <p className={styles.modalSubtitle}>{"Your concierge will be in touch shortly with booking details."}</p>
        <button className={styles.modalButtonYes} onClick={handleClose2}>{"Done"}</button>
      </div>
    </ReactModal>
  )

  var infoModule = agendaInfo ? <></> : <></>;

  // initializing itinerary module
  var itineraryModule = agendaInfo ?
    <AgendaItineraryModule agendaInfo={agendaInfo} isGuest={false}/> :
    <></>

  // initializing lodging module
  var lodgingModule = agendaInfo && agendaInfo["LodgingID (from LodgingOptions)"] ? 
    <ListingModule listingType={'Lodging'}
                     dataFunction={() => controller.lodging_by_ids(agendaInfo["LodgingID (from LodgingOptions)"])}
                     isGuest={false}
                     showBrexCards={false}
                     title={'Lodging'}/> :
    <></>

  // initializing activity module
  var activityModule = agendaInfo && agendaInfo["ActivityID (from ActivityOptions)"]?
    <ListingModule listingType={'Activity'}
                     dataFunction={() => controller.activities_by_ids(agendaInfo["ActivityID (from ActivityOptions)"])}
                     isGuest={false}
                     showBrexCards={false}
                     title={'Activities'}/> :
    <></>

  // initializing restaurant module
  var restaurantModule = agendaInfo && agendaInfo["RestaurantID (from RestaurantOptions)"] ?
    <ListingModule listingType={'Restaurant'}
                     dataFunction={() => controller.restaurants_by_ids(agendaInfo["RestaurantID (from RestaurantOptions)"])}
                     isGuest={false}
                     showBrexCards={false}
                     title={'Restaurants'}/> :
    <></>

  return (
    <>
      {messageModal}
      {messageModal2}
      <div className={styles.verticalFlexBox}>
        {conciergeBox}
        <div className={styles.overallBox}>
          {infoModule}
          {itineraryModule}
          {lodgingModule}
          {activityModule}
          {restaurantModule}
        </div>  
      </div> 
    </>
  );
}

export default AgendaViewingPage;

