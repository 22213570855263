import React from 'react';

// styles and components
import styles from '../styles/destinations.module.css';

// images
import oneBrexCards from '../images/one-brex.png';
import twoBrexCards from '../images/two-brex.png';
import threeBrexCards from '../images/three-brex.png';
import fourBrexCards from '../images/four-brex.png';

const DestinationCard = ( props ) => {

  var cityName = props.locationInfo['cityName'];
  var heroImage = props.locationInfo['heroImage'];
  var brexCards = props.locationInfo['brexCards'];

  var brexCardUrl;
  switch(brexCards) {
    case 2: brexCardUrl = twoBrexCards; break;
    case 3: brexCardUrl = threeBrexCards; break;
    case 4: brexCardUrl = fourBrexCards; break;
    default: brexCardUrl = oneBrexCards;
  }

  return (
    <div className={styles.card}>
      <img className={styles.cityHeroImage} src={heroImage} alt={cityName}/>
      <div className={styles.labelRow}> 
        <p className={styles.cityName}>{cityName}</p>
        <img className={styles.brexCards} src={brexCardUrl} alt={brexCards}/>
      </div>
    </div>
  );
}

export default DestinationCard