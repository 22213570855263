import React, { useState, useEffect } from 'react';

// styles and components
import styles from '../../styles/itinerary.module.css'
import gs from '../../styles/global.module.css'

// images
var placeholderImage = require("../../images/itinerary.png");

function parseMarkdown(markdownText) {

  if (typeof markdownText === 'undefined' || markdownText === '') { return '' }

  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')

  return '<p>' + htmlText.trim() + '</p>'
}

const ItineraryModule = ( props ) => {

  const [itineraryArr, setItineraryArr] = useState(null);
  const [itineraryId, setItineraryId] = useState(0);

  useEffect(() => {
    props.dataFunction().then(res => setItineraryArr(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // exit out if we haven't yet pulled from server
  if (!itineraryArr || itineraryArr.length === 0) { return <></> }

  // initializing the buttons
  var buttonArr = itineraryArr.map((prop, key) => {

    var buttonStyle = (prop['id'] === itineraryId) ? styles.buttonPressed : styles.buttonUnpressed;
    var textStyle = (prop['id'] === itineraryId) ? styles.textPressed : styles.textUnpressed;

    // todo(kbharathala@) replace this with a button
    return (
      <div key={prop['id']} 
           className={buttonStyle} 
           onClick={() => setItineraryId(prop['id'])} >
        <p className={textStyle}>{prop['title']}</p>
      </div> 
    )
  });
  
  // initializing the itinerary array with a bunch of spaghetti 
  var itinerary_arr = itineraryArr[itineraryId]['itinerary'].map((prop, key) => {
    var textBox = (
      <div className={styles.itineraryText}>
          <p className={styles.text1}>{"DAY " + (prop.id+1).toString()}</p>
          <p className={styles.text2}>{prop.title}</p>
          <p className={styles.text3}>{prop.description}</p>
          <p className={styles.text4} dangerouslySetInnerHTML={{__html: parseMarkdown(prop.details)}}/>
      </div>
    )

    var itineraryImage = prop.image ? prop.image[0].url : placeholderImage;

    return (
      <div className={styles.itineraryRow} key={"day" + (prop.id+1).toString()}>
        {textBox}
        <img className={styles.itinImage} src={itineraryImage} alt={'Itinerary'}/>
      </div>
    )
    
  });

  return (
    <div className={styles.overallModule} >
      <p className={styles.titleText}>{props.title}</p>
      <div className={styles.buttonHouse}>
        {buttonArr}
      </div>
      <div className={gs.verticalFlexBox}>
        {itinerary_arr}
      </div>
    </div>
  ); 

}

export default ItineraryModule
