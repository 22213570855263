import React, { useState } from 'react';
import { Formik, Field, Form} from 'formik';
import { Redirect } from 'react-router-dom';

import SubmissionCard from './SubmissionCard.js';
import styles from '../../styles/submissionInfo.module.css'

const SubmissionInfoPage = (props) => {

  const [formValues, setFormValues] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const stepCounter = (
    <div className={styles.stepCounter}>
      <p className={styles.currStep}>{"1. OFFSITE INFORMATION"}</p>
      <hr className={styles.notCurrLine}/>
      <p className={styles.notCurrStep}>{"2. PREFERENCES"}</p>
      <hr className={styles.notCurrLine}/>
      <p className={styles.notCurrStep}>{"3. ADDITIONAL COMMENTS"}</p>
    </div>
  )

  const formikForm = (
    <Formik
      initialValues={{
        PlannerName: '',
        Name: '',
        StartDate: '',
        EndDate: '',
        Guests: '',
        Department: '',
        Objective: '',
        Budget: '',
        CityName: props.match.params.cityName,
      }}

      onSubmit={(values, actions) => {
        setTimeout(() => {
          setFormValues(values);
          setRedirect(true);
        }, 1000);
      }}
    >
      {formik => (
        <Form>
          <div className={styles.form}>
            <div className={styles.horizontalBox}>
              <div className={styles.verticalBox}>
                <label htmlFor="PlannerName">{"Name (First Initial & Last Name)*"}</label>
                <Field name="PlannerName" placeholder="J. Smith"/>
              </div>
              <div className={styles.verticalBox}>
                <label htmlFor="Name">{"Name of Offsite *"}</label>
                <Field name="Name"/>
              </div>
            </div>
            <div className={styles.horizontalBox}>
              <div className={styles.verticalBox}>
                <label htmlFor="StartDate">Start Date *</label>
                <Field name="StartDate" placeholder="Start" type="date"/>
              </div>
              <div className={styles.verticalBox}>
                <label htmlFor="EndDate">End Date *</label>
                <Field name="EndDate" placeholder="End" type="date" />
              </div>
            </div>

            <div className={styles.horizontalBox}>
              <div className={styles.verticalBox}>
                <label htmlFor="Guests">Number of Attendees *</label>
                <Field name="Guests" type="number"/>
              </div>
              <div className={styles.verticalBox}>
                <label htmlFor="Department">Department *</label>
                <Field name="Department" />
              </div>
            </div>

            <div className={styles.horizontalBox}>
              <div className={styles.verticalBox}>
                <label htmlFor="Objective">Offsite Objective *</label>
                <Field name="Objective"/>
              </div>
              <div className={styles.verticalBox}>
                <label htmlFor="Budget">Budget</label>
                <Field name="Budget" placeholder="$" type="number"/>
              </div>
            </div>
          </div>
          <hr className={styles.hairline}/>
          <div className={styles.buttonDiv}>
            <button type="submit">{"Select Offsite Preferences"}</button>
          </div>
        </Form>
      )}
    </Formik>
  )

  return (
    !redirect ? (
      <div className={styles.wholePage}>
        <div className={styles.container}>
          {stepCounter}
          <p className={styles.formTitle}>Information about your offsite</p>
          {formikForm}
        </div>
        <SubmissionCard cityName={props.match.params.cityName} cardInfo={null}/>
      </div>
    ) : 
    (
      <Redirect to={{
        pathname: '/submission-page2/' + props.match.params.cityName,
        state: { formValues: formValues }
      }} />
    )
  );

};

export default SubmissionInfoPage;