import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { layoutGenerator } from 'react-break';

// components and styles
import ListingCard from '../City/ListingCard'
import styles from '../../styles/listingModule.module.css';

const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const ListingModule = ( props ) => {

  const [listingArr, setListingArr] = useState([]);
  const history = useHistory();

  // only called once on "component did mount"
  useEffect(() => {
    props.dataFunction().then(res => setListingArr(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  var endpoint = process.env.REACT_APP_DOMAIN + '/' + props.listingType.toLowerCase() + '/';

  var listingGrid = 
    listingArr.length === 0 ? <></> : 
    listingArr.map(listing => 
      <div onClick={() => 
        window.open(endpoint + listing['listingInfo']['ListingID'] + (props.isGuest ? '?guest' : ''))} 
          key={listing['listingInfo']['ListingID']}>
        <ListingCard 
          name={listing['listingInfo']['ListingName']} 
          brexCards={props.showBrexCards ? listing['listingInfo']['BrexCards'] : 0} 
          heroImage={listing['listingInfo']['HeroImage']}
          isGuest={props.isGuest}
        />
      </div>
    )

  var listingGridMobile = 
    listingArr.length === 0 ? <></> : 
    listingArr.map(listing => 
      <div onClick={() => 
          history.push('/' + props.listingType.toLowerCase() + '/' + listing['listingInfo']['ListingID'] + (props.isGuest ? '?guest' : ''))}
          key={listing['listingInfo']['ListingID']}>
        <ListingCard 
          name={listing['listingInfo']['ListingName']} 
          brexCards={props.showBrexCards ? listing['listingInfo']['BrexCards'] : 0} 
          heroImage={listing['listingInfo']['HeroImage']}
          isGuest={props.isGuest}
        />
      </div>
    )

  return (
    <>
      <OnAtLeastTablet>
        <div className={styles.overallModule} >
          <p className={styles.titleText}>{props.title}</p>
          <ul className={styles.destinationGrid}>{listingGrid}</ul>
        </div>
      </OnAtLeastTablet>
      <OnAtMostPhablet>
        <div className={styles.overallModuleMobile} >
          <p className={styles.titleText}>{props.title}</p>
          <ul className={styles.destinationGridMobile}>{listingGridMobile}</ul>
        </div>
      </OnAtMostPhablet>
    </>
        
  );
}

export default ListingModule