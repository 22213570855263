import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Field, Form} from 'formik';

import SubmissionCard from './SubmissionCard.js';

import styles from '../../styles/submissionPreference.module.css'

const SubmissionPreferencePage = (props) => {

  const [formValues, setFormValues] = useState(null);
  const [redirect, setRedirect] = useState(false);

  // if this page was rendered without rendering the first page, redirect back. 
  if (!props.location.state) {
    return (
      <Redirect to={{
        pathname: '/submission-page1/' + props.match.params.cityName,
      }}/>
    )
  }

  const stepCounter = (
    <div className={styles.stepCounter}>
      <p className={styles.currStep}>{"1. OFFSITE INFORMATION"}</p>
      <hr className={styles.currLine}/>
      <p className={styles.currStep}>{"2. PREFERENCES"}</p>
      <hr className={styles.notCurrLine}/>
      <p className={styles.notCurrStep}>{"3. ADDITIONAL COMMENTS"}</p>
    </div>
  )

  const lodgingSection = (
    <>
      <div className={styles.form}>
        <p className={styles.formSectionHeader}>Accommodations</p>
        <label htmlFor="RequiresAccommodation">{"Will the team need accommodations? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="radio" name="RequiresAccommodation" value='yes'/> 
          <p className={styles.radioLabel}>Yes</p>
          <Field type="radio" name="RequiresAccommodation" value='no'/> 
          <p className={styles.radioLabel}>No</p>
        </div>
        <label htmlFor="AccommodationPreferences">{"What type of accommodations do you prefer *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="checkbox" name="AccommodationPreferences" value='hotel'/> 
          <p className={styles.radioLabel}>Hotel</p>
          <Field type="checkbox" name="AccommodationPreferences" value='bnb'/> 
          <p className={styles.radioLabel}>Bed and Breakfast</p>
          <Field type="checkbox" name="AccommodationPreferences" value='vacation_home'/> 
          <p className={styles.radioLabel}>Vacation Home</p>
          <Field type="checkbox" name="AccommodationPreferences" value='cabin'/> 
          <p className={styles.radioLabel}>Cabin</p>
        </div>
        <label htmlFor="AccommodationAmenities">{"Amenities *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="checkbox" name="AccommodationAmenities" value='wifi'/> 
          <p className={styles.radioLabel}>WiFi</p>
          <Field type="checkbox" name="AccommodationAmenities" value='av-equipment'/> 
          <p className={styles.radioLabel}>AV Equipment</p>
          <Field type="checkbox" name="AccommodationAmenities" value='meeting-space'/> 
          <p className={styles.radioLabel}>Meeting Space</p>
          <Field type="checkbox" name="AccommodationAmenities" value='fitness-center'/> 
          <p className={styles.radioLabel}>Fitness Center</p>
          <Field type="checkbox" name="AccommodationAmenities" value='pet-friendly'/> 
          <p className={styles.radioLabel}>Pet Friendly</p>
          <Field type="checkbox" name="AccommodationAmenities" value='ada-access'/> 
          <p className={styles.radioLabel}>ADA Accessible</p>
          <Field type="checkbox" name="AccommodationAmenities" value='pool'/> 
          <p className={styles.radioLabel}>Pool</p>
        </div>
        <label htmlFor="AccommodationNotes">{"Additional notes for accommodations *"}</label>
        <Field as="textarea" name="AccommodationNotes" />
      </div>
      <hr className={styles.hairline}/>
    </>
  )

  const workspaceSection = (
    <>
      <div className={styles.form}>
        <p className={styles.formSectionHeader}>Workspace</p>
        <label htmlFor="RequiresWorkspace">{"Will the team need a workspace? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="radio" name="RequiresWorkspace" value='yes'/> 
          <p className={styles.radioLabel}>Yes</p>
          <Field type="radio" name="RequiresWorkspace" value='no'/> 
          <p className={styles.radioLabel}>No</p>
        </div>
        <label htmlFor="WorkspaceAmenities">{"Amenities *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="checkbox" name="WorkspaceAmenities" value='whiteboards'/> 
          <p className={styles.radioLabel}>Whiteboards</p>
          <Field type="checkbox" name="WorkspaceAmenities" value='av-equipment'/> 
          <p className={styles.radioLabel}>AV Equipment</p>
          <Field type="checkbox" name="WorkspaceAmenities" value='breakout-spaces'/> 
          <p className={styles.radioLabel}>Breakout Spaces</p>
          <Field type="checkbox" name="WorkspaceAmenities" value='office-supplies'/> 
          <p className={styles.radioLabel}>Office Supplies</p>
        </div>
        <label htmlFor="WorkspaceNotes">{"Additional notes for workspace *"}</label>
        <Field as="textarea" name="WorkspaceNotes" />
      </div>
      <hr className={styles.hairline}/>
    </>
  )

  const activitySection = (
    <>
      <div className={styles.form}>
        <p className={styles.formSectionHeader}>Activities</p>
        <label htmlFor="RequiresActivities">{"Will you have team activities? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="radio" name="RequiresActivities" value='yes'/> 
          <p className={styles.radioLabel}>Yes</p>
          <Field type="radio" name="RequiresActivities" value='no'/> 
          <p className={styles.radioLabel}>No</p>
        </div>
        <label htmlFor="ActivityInterests">{"What type of activities are you interested in? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="checkbox" name="ActivityInterests" value='creative-craft'/> 
          <p className={styles.radioLabel}>Creative / Craft</p>
          <Field type="checkbox" name="ActivityInterests" value='outdoors'/> 
          <p className={styles.radioLabel}>Outdoors</p>
          <Field type="checkbox" name="ActivityInterests" value='indoors'/> 
          <p className={styles.radioLabel}>Indoors</p>
          <Field type="checkbox" name="ActivityInterests" value='active'/> 
          <p className={styles.radioLabel}>Active</p>
          <Field type="checkbox" name="ActivityInterests" value='unique-experiences'/> 
          <p className={styles.radioLabel}>Unique Experiences</p>
        </div>
        <label htmlFor="ActivityNotes">{"Additional notes for activities *"}</label>
        <Field as="textarea" name="ActivityNotes" />
      </div>
      <hr className={styles.hairline}/>
    </>
  )

  const foodSection = (
    <>
      <div className={styles.form}>
        <p className={styles.formSectionHeader}>Food & Beverage</p>
        <label htmlFor="RequiresFood">{"Will you require food service? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="radio" name="RequiresFood" value='yes'/> 
          <p className={styles.radioLabel}>Yes</p>
          <Field type="radio" name="RequiresFood" value='no'/> 
          <p className={styles.radioLabel}>No</p>
        </div>
        <label htmlFor="FoodInterests">{"What food and beverage options are you interested in? *"}</label>
        <div className={styles.horizontalBox} style={{paddingTop: 9}}>
          <Field type="checkbox" name="FoodInterests" value='breakfast-catering'/> 
          <p className={styles.radioLabel}>Breakfast Catering</p>
          <Field type="checkbox" name="FoodInterests" value='lunch-catering'/> 
          <p className={styles.radioLabel}>Lunch Catering</p>
          <Field type="checkbox" name="FoodInterests" value='dinner-catering'/> 
          <p className={styles.radioLabel}>Dinner Catering</p>
          <Field type="checkbox" name="FoodInterests" value='restaurant-reservations'/> 
          <p className={styles.radioLabel}>Restaurant Reservations</p>
        </div>
        <label htmlFor="FoodNotes">{"Additional notes for food & beverages *"}</label>
        <Field as="textarea" name="FoodNotes" />
      </div>
      <hr className={styles.hairline}/>
    </>
  )



  const formikForm = (
    <Formik
      initialValues = {{
        RequiresAccommodation: 'yes',
        AccommodationPreferences: [],
        AccommodationAmenities: [],
        AccommodationNotes: '',
        RequiresWorkspace: 'yes',
        WorkspaceAmenities: [],
        WorkspaceNotes: '',
        RequiresActivities: 'yes',
        ActivityInterests: [],
        ActivityNotes: '',
        RequiresFood: 'yes',
        FoodInterests: [],
        FoodNotes: '',
      }}
      onSubmit = {(values) => {
        setFormValues(values);
        setRedirect(true);
      }}
    >
      <Form>
        {lodgingSection}
        {workspaceSection}
        {activitySection}
        {foodSection}
        <div className={styles.buttonDiv}>
          <button type="submit">{"Add Additional Details"}</button>
        </div>
      </Form>
    </Formik>
  )

  return (
    !redirect ? (
      <div className={styles.wholePage}>
        <div className={styles.container}>
          {stepCounter}
          <p className={styles.formTitle}>{"Offsite Preferences"}</p>
          {formikForm}
        </div>
        <SubmissionCard cityName={props.match.params.cityName} cardInfo={props.location.state.formValues}/>
      </div>
    ) : 
    (
      <Redirect to={{
        pathname: '/submission-page3/' + props.match.params.cityName,
        state: { formValues1: props.location.state.formValues, formValues2: formValues }
      }} />
    )
  );

};

export default SubmissionPreferencePage;