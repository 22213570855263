import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// styles and components
import DestinationCard from './DestinationCard';
import styles from '../styles/destinations.module.css';

import controller from '../data/dataController';

const DestinationIndex = ( props ) => {

  const [locations, setLocations] = useState([]);
  const history = useHistory();

  useEffect(() => {
    controller.list_locations()
    .then(res => setLocations(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  var locationGrid = 
    locations.length === 0 ? <></> : 
    locations.map(location => 
      <div onClick={() => history.push('/city/' + location['cityName'])} key={location['cityName']}>
        <DestinationCard locationInfo={location} />
      </div>
    )

  return (
    <div className={styles.wholePage}>
      <p className={styles.headerText}>{"Available Destinations"}</p>
      <ul className={styles.locationGrid}>{locationGrid}</ul>
    </div>
  );

}

export default DestinationIndex;