import React, { useState, useEffect } from 'react';

// styles and components 
import RequestCard from '../Shared/RequestCard.js'
import ItineraryModule from './ItineraryModule.js'
import ListingModule from './ListingModule'

import styles from '../../styles/citypage.module.css';
import gs from '../../styles/global.module.css';

var controller = require('../../data/dataController');

function parseMarkdown(markdownText) {

  if (typeof markdownText === 'undefined' || markdownText === '') { return '' }
    
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')

  return '<p>' + htmlText.trim() + '</p>'
}

const DestinationPage = ( props ) => {

  const [cityInfo, setCityInfo] = useState(null);

  // pulling this from the URL
  var cityName = props.match.params.cityName;

  // only called once on "component did mount"
  useEffect(() => {
    controller.city_info(cityName)
    .then(res => setCityInfo(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // initializing header image
  var heroImageUrl = (cityInfo && cityInfo['HeroImage']) ? 
    cityInfo['HeroImage'][0]['url'] : require('../../images/loading.png')

  var heroImageUrlStyle = { backgroundImage: "url(" + heroImageUrl + ")" }

  var headerImage = 
    <div style={heroImageUrlStyle} className={styles.headerImage}>
      <h1 className={styles.headerImageText}>{cityName}</h1>
    </div>

  // initializing header text
  var teaserText = cityInfo ? cityInfo['LocationTeaser'] : "";
  var overviewText = cityInfo && cityInfo['LocationOverview'] ? parseMarkdown(cityInfo['LocationOverview']) : "";

  var headerText = (
    <div className={styles.textView1}>
      <p className={styles.introText}>{teaserText}</p>
      <p className={styles.detailText} dangerouslySetInnerHTML={{__html: overviewText}}/>
    </div>
  )

  // initializing request module 
  var requestCard = (cityInfo) ? 
    <RequestCard 
      cityName={cityName} 
      description={cityInfo['OffsiteDescription']} 
      brexCards={cityInfo['BrexCards']}
    /> : 
    <></>

  // initializing itinerary module
  var itineraryModule = (cityInfo && cityInfo['Itineraries'] && cityInfo['Itineraries'].length > 0) ?
     <ItineraryModule dataFunction={() => controller.list_itineraries_by_city(cityName)}
                      title={"Suggested Itineraries"}/> :
     <></>

  // initializing lodging module
  var lodgingModule = (cityInfo && cityInfo['Lodging'] && cityInfo['Lodging'].length > 0) ?
    <ListingModule listingType={'Lodging'}
                   dataFunction={() => controller.lodging_by_city(cityName)}
                   showBrexCards={true}
                   title={"Lodging Options"}/> :
    <></>

  // initializing activity module
  var activityModule = (cityInfo && cityInfo['Activities'] && cityInfo['Activities'].length > 0) ?
    <ListingModule listingType={'Activity'}
                   dataFunction={() => controller.activities_by_city(cityName)}
                   showBrexCards={false}
                   title={"Activity Options"}/> :
    <></>

  // initializing restaurant module
  var restaurantModule = (cityInfo && cityInfo['Food'] && cityInfo['Food'].length > 0) ?
    <ListingModule listingType={'Restaurant'}
                   dataFunction={() => controller.restaurants_by_city(cityName)}
                   showBrexCards={false}
                   title={"Restaurant Options"}/> :
    <></>

  return (
    <div className={styles.wholePage}>
      {headerImage}
      <div className={styles.contentBox}>
        <div className={gs.horizontalFlexBox}>
          {headerText}
          {requestCard}
        </div>
        {itineraryModule}
        {lodgingModule}
        {activityModule}
        {restaurantModule}
      </div>
    </div>   
  );

}

export default DestinationPage

