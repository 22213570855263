import React from 'react';
import { layoutGenerator } from 'react-break';

// import styles & components
import styles from '../../styles/agendaItinerary.module.css';
import m_styles from '../../styles/agendaItineraryMobile.module.css';

const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

function parseMarkdown(markdownText) {

  if (typeof markdownText === 'undefined' || markdownText === '') { return '' }
    
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')

  return '<p>' + htmlText.trim() + '</p>'
}

const AgendaItineraryModule = ( props ) => {

  var itineraryDetails = props.agendaInfo
  var itineraryArr = [];
  var itineraryArrMobile = [];

  for (var i = 1; i <= itineraryDetails['Days']; i++) {

    var introString = 'ItineraryDay' + i.toString();
    var title = itineraryDetails[introString + 'Title']
    var description = itineraryDetails[introString + 'Description']
    var details = parseMarkdown(itineraryDetails[introString + 'Details'])
    var image = itineraryDetails[introString + 'Image'][0]['url']

    var textBox = (
      <div className={styles.itineraryText}>
          <p className={styles.text1}>{"DAY " + i.toString()}</p>
          <p className={styles.text2}>{title}</p>
          <p className={styles.text3}>{description}</p>
          <p className={styles.text4} dangerouslySetInnerHTML={{__html: details}}/>
      </div>
    )

    var textBoxMobile = (
      <div className={m_styles.itineraryText}>
          <p className={m_styles.text1}>{"DAY " + i.toString()}</p>
          <p className={m_styles.text2}>{title}</p>
          <p className={m_styles.text4} dangerouslySetInnerHTML={{__html: details}}/>
      </div>
    )

    var itineraryDay = (
      <div className={styles.itineraryRow} key={"day" + i.toString()}>
        {textBox}
        <img className={styles.itineraryImageRight} src={image} alt={""}/>
      </div>
    )

    var itineraryDayMobile = (
      <div key={"day" + i.toString()}>
        <img className={m_styles.itineraryImage} src={image} alt={""}/>
        {textBoxMobile}
      </div>
    )

    itineraryArr.push(itineraryDay);
    itineraryArrMobile.push(itineraryDayMobile);

  }

  return ( 
    <>
      <OnAtLeastTablet>
        <div className={styles.overallModule} >
          {props.isGuest ? <></> : <p className={styles.titleText}>{"Itinerary"}</p>}
          <div className={styles.itineraryGrid}>
            {itineraryArr}
          </div>
        </div>
      </OnAtLeastTablet>
      <OnAtMostPhablet>
        <div className={m_styles.overallModule}>
          {props.isGuest ? <></> : <p className={m_styles.titleText}>{"Itinerary"}</p>}
          {itineraryArrMobile}
        </div> 
      </OnAtMostPhablet>
    </>
  ); 
  
} 

export default AgendaItineraryModule
