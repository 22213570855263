import React from 'react';
import { layoutGenerator } from 'react-break';

// components and styles
import styles from '../../styles/listingModule.module.css';

//images 
import oneBrexCards from '../../images/one-brex.png';
import twoBrexCards from '../../images/two-brex.png';
import threeBrexCards from '../../images/three-brex.png';
import fourBrexCards from '../../images/four-brex.png';

// setting up the mobile breakpoints
const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const ListingCard = ( props ) => {

  var listingName = props.name;
  var listingImage = props.heroImage;
  var brexCards = props.brexCards;

  // initialize brexcard component if it is needed
  if (brexCards) {
    var brexComponent;
    switch(brexCards) {
      case 2: brexComponent = <img className={styles.brexCards} src={twoBrexCards} alt={"brex cards"}></img>; break;
      case 3: brexComponent = <img className={styles.brexCards} src={threeBrexCards} alt={"brex cards"}></img>; break;
      case 4: brexComponent = <img className={styles.brexCards} src={fourBrexCards} alt={"brex cards"}></img>; break;
      default: brexComponent = <img className={styles.brexCards} src={oneBrexCards} alt={"brex cards"}></img>
    }
  }

  // default image if the listing doesn't have an image
  var heroImageUrl = (listingImage) ? 
    listingImage[0]['url'] : require('../../images/activity.png')

  var desktopCard = (
    <div className={styles.overallCard} >
      <img className={styles.heroImage} src={heroImageUrl} alt={""}></img>
      <div className={styles.textRow}>
        <p className={styles.listingName}>{listingName}</p>
        {(brexCards && !props.isGuest) ? brexComponent : <></>}
      </div>
    </div>
  )

  var mobileCard = (
    <div className={styles.overallCardMobile} >
      <img className={styles.heroImage} src={heroImageUrl} alt={""}></img>
      <div className={styles.textRow}>
        <p className={styles.listingName}>{listingName}</p>
        {(brexCards && !props.isGuest) ? brexComponent : <></>}
      </div>
    </div>
  )

    return (
      <>
        <OnAtMostPhablet>
          {mobileCard}
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          {desktopCard}
        </OnAtLeastTablet>
      </>
    )
}

export default ListingCard