import React from 'react';
import { Redirect } from 'react-router-dom';
import { layoutGenerator } from 'react-break';

// okta information
import { useOktaAuth } from '@okta/okta-react';

//components & styles
import styles from '../../styles/home.module.css';

// images
import offsiterLogo from '../../images/logo.png';
import brexLogo from '../../images/brex_logo.png';
import loginImage from '../../images/login_hero.png';

const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const Home = ({ config }) => {

  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) return null;
  if (authState.isAuthenticated) { return(<Redirect to="/destinations" />) }

  const login = async () => { await oktaAuth.signInWithRedirect(); }

  return (
    <>
      <OnAtLeastTablet>
        <div className={styles.container}>
          <img src={loginImage} alt={""} className={styles.imageDiv} />
          <div className={styles.rightPane}>
            <div className={styles.loginInfo}>
              <img src={brexLogo} alt={"Brex Logo"} className={styles.brexLogoImage}/>
              <p className={styles.text1}>Enterprise Managed Portal</p>
              <button onClick={login}>{"Login with Okta"}</button>
            </div>
            <img src={offsiterLogo} alt={"Offsiter Logo"} className={styles.offsiterLogo}/>
          </div>
        </div>
      </OnAtLeastTablet>

      <OnAtMostPhablet>
        <div className={styles.loginInfo}>
          <img src={brexLogo} alt={"Brex Logo"} className={styles.brexLogoImage}/>
          <p className={styles.text1Mobile}>{"Enterprise Managed Portal"}</p>
          <button onClick={login}>{"Login with Okta"}</button>
          <img src={offsiterLogo} alt={"Offsiter Logo"} className={styles.offsiterLogoMobile}/>
        </div>
      </OnAtMostPhablet>
    </>
  );
};

export default Home;