import React, {useState} from 'react';
import ReactModal from 'react-modal';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik, Field, Form} from 'formik';

// import styles and components
import SubmissionCard from './SubmissionCard.js';
import controller from '../../data/dataController';
import styles from '../../styles/submissionDetail.module.css'

const SubmissionDetailsPage = (props) => {

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [formValues, setFormValues] = useState(null);

  var history = useHistory();

  // if this page was rendered without rendering the first page, redirect back. 
  if (!props.location.state) {
    return (
      <Redirect to={{
        pathname: '/submission-page1/' + props.match.params.cityName,
      }}/>
    )
  }

  const stepCounter = (
    <div className={styles.stepCounter}>
      <p className={styles.currStep}>{"1. OFFSITE INFORMATION"}</p>
      <hr className={styles.currLine}/>
      <p className={styles.currStep}>{"2. PREFERENCES"}</p>
      <hr className={styles.currLine}/>
      <p className={styles.currStep}>{"3. ADDITIONAL COMMENTS"}</p>
    </div>
  )

  var cardInfo = props.location.state.formValues1;
  var preferenceInfo = props.location.state.formValues2;

  // doing stupid date transforms 
  var startDate = new Date(cardInfo.StartDate);
  var endDate = new Date(cardInfo.EndDate);

  var getDaysArray = (start, end) => {
    for (var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
    }
    return arr;
  };

  var dayList = getDaysArray(startDate, endDate);

  var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

  var dateArray = dayList.map((day) => days[day.getDay()] + ", " + months[day.getMonth()] + " " + day.getDate());

  var initialValues = {};
  var sections = [];

  for (var i = 1; i <= dateArray.length; i++) {
    var key = 'Day' + i.toString() + 'Notes';
    var formattedKey = 'Day ' + i.toString() + ': ' + dateArray[i-1];
    initialValues[key] = '';

    sections.push(
      <div key={key}>
        <div className={styles.form}>
          <p className={styles.formSectionHeader}>{formattedKey}</p>
          <label htmlFor={key}>{"What are your plans? *"}</label>
          <Field as="textarea" name={key} />
        </div>
        <hr className={styles.hairline}/>
      </div>
    )
  }

  const formikForm = (
    <Formik
      initialValues = {initialValues}
      onSubmit = {(values) => {
        setFormValues(values);
        handleShow1();
      }}
    >
      <Form>
        {sections}
        <div className={styles.buttonDiv}>
          <button type="submit">{"Submit Booking Request"}</button>
        </div>
      </Form>
    </Formik>
  )

  const handleClose1 = () => {
    setShowModal1(false);
  }

  const handleShow1 = () => {
    setShowModal1(true);
  }

  const handleClose2 = () => {
    setShowModal2(false);
    history.push("/agendas");

  }
  
  const handleShow2 = () => {
    controller.submitForm([formValues, cardInfo, preferenceInfo]);
    setShowModal1(false);
    setShowModal2(true);
  }


  const modalStyles = {
    content: {
      position: 'absolute',
      top: '20%',
      left: '30%',
      width: '40%',
      right: 'auto',
      bottom: 'auto',
    },
  }

  const modal1 = (
    <ReactModal isOpen={showModal1} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.verticalFlexBox}>
        <p className={styles.modalTitle}>{"Ready to submit request?"}</p>
        <p className={styles.modalSubtitle}>{"Do you want to do review your request one last time? Once submitted, our concierge will use the information you provided to begin planning your offsite"}</p>
        <button className={styles.modalButtonYes} onClick={handleShow2}>Confirm Booking Request</button>
        <button className={styles.modalButtonNo} onClick={handleClose1}>Review Request</button>
      </div>
    </ReactModal>
  )

  const modal2 = (
    <ReactModal isOpen={showModal2} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.verticalFlexBox}>
        <p className={styles.modalTitle}>{"Your request for \"" + cardInfo['Name'] + "\" has been submitted!"}</p>
        <p className={styles.modalSubtitle}>{"Our concierge will review your offsite request and get back to you as soon as possible with a proposed offsite or additional questions."}</p>
        <button className={styles.modalButtonYes} onClick={handleClose2}>Go to My Agendas</button>
      </div>
    </ReactModal>
  )

  return (
    <div className={styles.wholePage}>
      {modal1}
      {modal2}
      <div className={styles.container}>
        {stepCounter}
        <p className={styles.formTitle}>{"Additional Details"}</p>
        <p className={styles.formSubtitle}>{"Have an idea of the agenda of your offsite? Please jot them down here to help our concierge make the best recommendations."}</p>
        {formikForm}
      </div>
      <SubmissionCard cityName={props.match.params.cityName} cardInfo={props.location.state.formValues1}/>
    </div>
  );

};

export default SubmissionDetailsPage;