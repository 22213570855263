import React from 'react';
import { layoutGenerator } from 'react-break';
import { useLocation } from 'react-router-dom';

// components and styles
import styles from '../../styles/footerbar.module.css'

// images
import offsiterLogo from '../../images/logo.png';

// setting up the mobile breakpoints
const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const FooterBar = ({ config }) => {

  // getting query parameter
  const { search } = useLocation()
  var isGuest = (search === '?guest') ? true : false;

  return (
    isGuest ? <></> :
    <>
      <OnAtLeastTablet>
        <div className={styles.contact}>
          <p className={styles.adviceText}>{"Looking for something specific? Need some advice?"}</p>
          <div className={styles.button}>
            <p className={styles.buttonText}>{"Contact your Offsiter Concierge"}</p>
          </div>
        </div>
        <div className={styles.footer}>
          <img className={styles.logo} src={offsiterLogo} alt={"Offsiter Logo"}/>
          <p className={styles.text1}>{"Managed Portal for Brex"}</p>
          <div className={styles.copyrightBar}>
            <p className={styles.text2}>{"Copyright 2021 Offsiter, Inc.  All rights reserved."}</p>
            <p className={styles.text3} onClick={() => window.open('https://offsiter.com/privacy-policy')}>{"Privacy Policy"}</p>
          </div>
        </div>
      </OnAtLeastTablet>
      <OnAtMostPhablet>
      </OnAtMostPhablet>
    </>
  );
};

export default FooterBar;